import { ISubscription, IUserSubscription } from "../models/subscription";
import { IUser, TContext } from "../models/user";
import { IWorkspace } from "../models/workspace";
import { IUserWorkspace } from "../response/workspace";

//Enums
export enum ActionType {
  SET_AUTH_DATA = "SET_AUTH_DATA",
  SET_USER = "SET_USER",
  SET_CONTEXT = "SET_CONTEXT",
  SET_LIFETIME = "SET_LIFETIME",
  SET_TRIAL = "SET_TRIAL",
  SET_VALID_SUBSCRIPTION = "SET_VALID_SUBSCRIPTION",
  SET_WORKSPACE = "SET_WORKSPACE",
  SET_WORKSPACE_OWNER = "SET_WORKSPACE_OWNER",
  SET_WORKSPACE_ADMIN = "SET_WORKSPACE_ADMIN",
  SET_SUBSCRIPTION = "SET_SUBSCRIPTION",
  SET_USER_WORKSPACES = "SET_USER_WORKSPACES",
  SET_DISABLE_PREMIUM_FEATURES = "SET_DISABLE_PREMIUM_FEATURES",
  SET_EXTENSION_INBOX_TASK_ID = "SET_EXTENSION_INBOX_TASK_ID",
  SET_DISCONNECTED_CONNECTIONS_COUNT = "DISCONNECTED_CONNECTIONS_COUNT",
  SET_IS_LOGGED_TO_LINKEDIN = "SET_IS_LOGGED_TO_LINKEDIN",
  RESET_STORE = "RESET_STORE",
  SET_SCHEDULED_REMOVAL = "SET_SCHEDULED_REMOVAL",
}

//Interface
export interface IAuthData {
  accessToken?: string;
  refreshToken?: string;
}

//States
export type TAuthState = {
  authData: IAuthData;
  context: TContext;
  user?: IUser;
  workspace?: IWorkspace;
  lifetime: boolean;
  trial: boolean;
  validSubscription: boolean;
  workspaceOwner: boolean;
  workspaceAdmin: boolean;
  subscription?: ISubscription | IUserSubscription;
  userWorkspaces: IUserWorkspace[];
  disablePremiumFeatures: boolean;
  extensionInboxTaskId?: string;
  disconnectedConnectionsCount?: number;
  isLoggedToLinkedin?: boolean;
  scheduledRemoval?: boolean;
};

//Actions
interface ISetAuthDataAction {
  type: ActionType.SET_AUTH_DATA;
  payload: TAuthState["authData"];
}

interface ISetUserData {
  type: ActionType.SET_USER;
  payload: TAuthState["user"];
}

interface ISetWorkspace {
  type: ActionType.SET_WORKSPACE;
  payload: TAuthState["workspace"];
}

interface ISetContext {
  type: ActionType.SET_CONTEXT;
  payload: TAuthState["context"];
}

interface ISetLifetime {
  type: ActionType.SET_LIFETIME;
  payload: TAuthState["lifetime"];
}

interface ISetTrial {
  type: ActionType.SET_TRIAL;
  payload: TAuthState["trial"];
}

interface ISetValidSubscription {
  type: ActionType.SET_VALID_SUBSCRIPTION;
  payload: TAuthState["validSubscription"];
}

interface ISetTeamOwner {
  type: ActionType.SET_WORKSPACE_OWNER;
  payload: TAuthState["workspaceOwner"];
}

interface ISetTeamAdmin {
  type: ActionType.SET_WORKSPACE_ADMIN;
  payload: TAuthState["workspaceAdmin"];
}

interface ISetSubscriptionAction {
  type: ActionType.SET_SUBSCRIPTION;
  payload: TAuthState["subscription"];
}

interface ISetUserWorkspaces {
  type: ActionType.SET_USER_WORKSPACES;
  payload: TAuthState["userWorkspaces"];
}

interface ISetDisablePremiumFeatures {
  type: ActionType.SET_DISABLE_PREMIUM_FEATURES;
  payload: TAuthState["disablePremiumFeatures"];
}

interface ISetExtensionInboxTaskId {
  type: ActionType.SET_EXTENSION_INBOX_TASK_ID;
  payload: TAuthState["extensionInboxTaskId"];
}

interface IResetStoreAction {
  type: ActionType.RESET_STORE;
}

interface ISetDisconnectedConnectionsCount {
  type: ActionType.SET_DISCONNECTED_CONNECTIONS_COUNT;
  payload: TAuthState["disconnectedConnectionsCount"];
}

interface ISetLoogedToLinkedin {
  type: ActionType.SET_IS_LOGGED_TO_LINKEDIN;
  payload: TAuthState["isLoggedToLinkedin"];
}

interface ISetScheduledRemoval {
  type: ActionType.SET_SCHEDULED_REMOVAL;
  payload: TAuthState["scheduledRemoval"];
}

export type TAuthAction =
  | ISetAuthDataAction
  | ISetUserData
  | ISetContext
  | ISetLifetime
  | ISetTrial
  | ISetValidSubscription
  | ISetWorkspace
  | ISetTeamOwner
  | ISetTeamAdmin
  | ISetSubscriptionAction
  | ISetUserWorkspaces
  | IResetStoreAction
  | ISetExtensionInboxTaskId
  | ISetDisablePremiumFeatures
  | ISetDisconnectedConnectionsCount
  | ISetLoogedToLinkedin
  | ISetScheduledRemoval;
