import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import { styles } from "../styles/themes/style";
import { useAppActions } from "../stores/application/useAppActions";
import SvgFragment from "../fragments/SvgFragment";

interface ISidebarProps extends ISidebarStyleProps {
    sidebar?: boolean,
    children?: ReactNode,
    onClose: (e: any) => void
}

interface ISidebarStyleProps {
    zIndex?: number
}

const Sidebar: FunctionComponent<ISidebarProps> = ({ children, sidebar = true, onClose }) => {

    return (
        <>
            {sidebar ? (
                <Container>
                    <CloseIcon onClick={onClose}>
                        <SvgFragment type={'close'} />
                    </CloseIcon>
                    {children}
                </Container>
            ) : (
                null
            )}
        </>
    )
}

const Container = styled.div<ISidebarStyleProps>`
    width: 328px;
    height: 100%;
    position: absolute;
    z-index: ${({zIndex}) => zIndex ??  '10000001'};
    right: 0;
    display: flex;
    background-color: ${ ({ theme: { secondaryColor }}) => secondaryColor};
    border-radius: 8px 0 0 8px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.10);
    padding: 24px 16px;
    @media (max-width: 768px) {
        width: 100%;
    }
`
const CloseIcon = styled.div`
    position: absolute;
    right: 16px;
    top: 20px;
    cursor: pointer;
    & .svg {
        height: 24px;
        width: 24px;
    }

    &:hover {
        path { fill: ${styles.colors.primary400} }
    }
`

export default Sidebar