import { FunctionComponent, useEffect, useState } from "react"
import { styles } from "../styles/themes/style";
import styled from "styled-components"
import Text from "./Text";
import { useAppHandlers } from "../services/actions/app/useAppHandlers";

interface IStyledInputProps {
    $error?: boolean,
    $disabled?: boolean,
    $noBorder?: boolean,
    $isSelected?: boolean,
    $border?: string,
}

export interface IInputProps extends IStyledInputProps {
    type?: string;
    spellCheck?: boolean;
    placeholder?: string;
    description?: string;
    prefilledValue?: string;
    enableDoubleClickCopy?: boolean
    onChangeHandler?: (e: any) => void;
    onKeyDownHandler?: (e: any) => void;
    onBlurHandler?: (e: any) => void;
    style?: any;
    maxLength?: number;

    // Any other props 
    [p: string]: any;
}

const Input: FunctionComponent<IInputProps> = (props: IInputProps) => {
    const {
        description,
        prefilledValue,
        value,
        enableDoubleClickCopy,
        onChangeHandler,
        onKeyDownHandler,
        onBlurHandler,
        disabled,
        $disabled,
        maxLength,
        ...other
    } = props;

    const { showSuccessToast } = useAppHandlers();

    const [$value, setValue] = useState(prefilledValue);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setValue(prefilledValue)
        setCounter(prefilledValue !== undefined ? prefilledValue.length : 0)
    }, [prefilledValue]);

    useEffect(() => {
        if (value !== $value) setValue(value);
        if(value?.length === 0){
            setCounter(value?.length);
        }
    }, [value, $value]);

    const onChangeStateHandler = (valueNew: string) => {
        setValue(valueNew);
        onChangeHandler && onChangeHandler(valueNew);
        setCounter(valueNew.length);
    };

    const doubleClickCopy = () => {
        const copyText = $value || prefilledValue
        if(copyText) {
            navigator.clipboard.writeText(copyText).then(() => {
                showSuccessToast({message: 'API Key copied'})
            })
        }
    }

    const isDisabled = disabled || $disabled;

    return (
        <Container>
            <StyledInput
                value={$value}
                onChange={({ target: { value } }) => !isDisabled && onChangeStateHandler(value)}
                onKeyDown={(e) => !isDisabled && onKeyDownHandler && onKeyDownHandler(e)}
                onBlur={(e) => !isDisabled && onBlurHandler && onBlurHandler(e)}
                onDoubleClick={enableDoubleClickCopy ? doubleClickCopy : undefined}

                $isSelected={counter > 0}

                disabled={isDisabled}

                {...other}
            />
            {maxLength ? (
                <Counter>
                    <Label>{`${counter}/${maxLength}`}</Label>
                </Counter>
            ) : null}
            {description && counter > 0 ? (
                <Counter>
                    <Text $heading6 $capitalizeAll>{description}</Text>
                </Counter>
            ) : null}

        </Container>
    )
}
const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`

const StyledInput = styled.input<IStyledInputProps>`
    font-family: NunitoSans;
    font-size: 14px;
    width: 100%;
    height: 34px;
    padding: 0 10px;
    line-height: 20px;
    outline: none;
    border-radius: 4px;
    ::placeholder { color: ${styles.colors.black300}; }
    color: ${({ theme: { primaryColor } }) => primaryColor};
    background-color: ${({ theme: { secondaryColor } }) => secondaryColor};
    ${({ theme: { quinaryColor } }) => `
        border: 2px solid ${quinaryColor};
        &:focus, &:hover { border: 2px solid ${styles.colors.primary600}; }`}

    ${({ $error }) => $error && `
        background-color: ${styles.colors.red200};
        border: 2px solid ${styles.colors.red600};
    `}
    
    ${({ $disabled, disabled }) => (disabled || $disabled) && `
        border: 2px solid ${styles.colors.black200};
        background-color: ${styles.colors.black200};
        pointer-events: none; 
        user-select: none;

        &:hover, &:focus{
            border: 2px solid ${styles.colors.black200};
            background-color: ${styles.colors.black200};
        }
    `}

    ${({ $isSelected }) => $isSelected ? `border: 2px solid ${styles.colors.primary600};` : null}

    ${({ $noBorder }) => $noBorder && `
        border: none;
        &:focus, &:hover { border: none; }
    `}
    ${({ $border }) => $border && `
        border: ${$border};
    `}
    ::-webkit-inner-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none;
        margin: 0;
    }
    
`
const Counter = styled.section`
    display: flex;
    justify-content: end;
    position: absolute;
    right: 5px;
`

const Label = styled(Text)`
    color: ${styles.colors.black400};
    text-transform: uppercase;
    font-size: 11px;
`
export default Input