import { FunctionComponent } from 'react'
import { useAppStore } from '../../../stores/application/useAppStore'

import CustomModal from '../CustomModal'

const DestructiveModal: FunctionComponent = () => {
    const { store: { modal } } = useAppStore()

    return (
        <CustomModal
            closeButton
            headingText={modal?.headingText}
            descriptionText={modal?.descriptionText}
            descriptionTextBold={modal?.descriptionTextBold}
            cancelButton={
                modal?.onCancelHandler ?
                    {
                        text: modal?.cancelBtnTitle || 'Cancel',
                        mediumButton: true,
                        onClickHandler: modal?.onCancelHandler
                    } : undefined
            }
            confirmButton={
                modal?.onConfirmHandler ?
                    {
                        text: modal?.confirmBtnTitle || 'Delete',
                        type: 'red',
                        mediumButton: !modal?.confirmBtnTitle,
                        extraBigButton: modal?.confirmBtnTitle,
                        onClickHandler: modal?.onConfirmHandler
                    } : undefined
            }
        />
    )
}

export default DestructiveModal