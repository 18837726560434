import { useAuthActions } from "../../../stores/auth/useAuthActions"
import { useWorkspaceUsersActions } from "../../../stores/workspaceUsers/useWorkspaceUsersActions"
import { useWorkspaceRequest } from "../../apis/workspace/useWorkspaceRequest"


export const useWorkspaceHandlers = () => {
    const { getWorkspaceUsers, updateWorkspace, getInvites, sendInvites, resendInvite, revokeInvite, updateWorkspaceUsers, getWorkspaceUsersPublic, getWorkspace, getWorkspaceUsersAndInvites } = useWorkspaceRequest()
    const { setWorkspace } = useAuthActions()
    const { resetWorkspaceUsersStore } = useWorkspaceUsersActions()
    
    const getWorkspaceUsersHandler = async (params: any) => {
        const api = `page=${params.page}&pageSize=${params.pageSize}&sort=${params.sort}&total=${params.total}&active=${params.active}`
        const result = await getWorkspaceUsers(api)
        return result
    }

    const getWorkspaceUsersAndInvitesHandler = async (params: any) => {
        const api = `page=${params.page}&pageSize=${params.pageSize}&sort=${params.sort}&total=${params.total}&active=${params.active}&resolved=${params.resolved}`
        const result = await getWorkspaceUsersAndInvites(api)
        return result
    }

    const getWorkspaceUsersPublicHandler = async () => {
        const result = await getWorkspaceUsersPublic()

        return result ?? []
    }

    const updateWorkspaceHandler = async (params: any) => {
        const result = await updateWorkspace(params)
        return result
    }

    const updateWorkspaceUsersHandler = async (params: any, flag: boolean) => {
        const result = await updateWorkspaceUsers({
            userId: params._id,
            body: {
                active: flag
            }
        })
        await refetchWorkspace()
        return result
    }

    const getInvitesHandlers = async (params: any) => {
        const api = `page=${params.page}&pageSize=${params.pageSize}&sort=${params.sort}&total=${params.total}&resolved=${params.resolved}`
        const result = await getInvites(api)
        return result
    }

    const sendInvitesHandlers = async (params: any) => {
        const result = await sendInvites(params)
        await refetchWorkspace()
        return result
    }

    const resendInviteHandler = async (inviteId: string) => {
        const result = await resendInvite(inviteId)
        await refetchWorkspace()
        return result
    }

    const revokeInviteHandler = async (params: {inviteId: string, body: any}) => {
        const { inviteId, body } = params
        const result = await revokeInvite({ inviteId, body })
        await refetchWorkspace()
        return result
    }

    const refetchWorkspace = async () => {
        const workspace = await getWorkspace()
        if(workspace)
            setWorkspace(workspace) 
    }

    const resetWorkspaceUsersStoreHandler = () => {
        resetWorkspaceUsersStore();
    }

    return {
        getWorkspaceUsersHandler,
        getWorkspaceUsersPublicHandler,
        updateWorkspaceHandler,
        updateWorkspaceUsersHandler,
        sendInvitesHandlers,
        getInvitesHandlers,
        resendInviteHandler,
        revokeInviteHandler,
        resetWorkspaceUsersStoreHandler,
        refetchWorkspace,
        getWorkspaceUsersAndInvitesHandler
    }
}