import { FunctionComponent, useEffect, useRef, useState } from 'react'
import Checkbox from '../../../../components/Checkbox'
import Table from '../../../../components/Table'
import Text from '../../../../components/Text'
import moment from 'moment'
import styled from 'styled-components'
import { styles } from '../../../../styles/themes/style'
import Column from '../../../../components/Column'
import { calculateColumnWidth, sanitize } from '../../../../utils'
import EditDeleteActionDropdown from '../../../../fragments/dropdowns/EditDeleteActionDropdown'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { useConnectionsHandlers } from '../../../../services/actions/connections/useConnectionsHandlers'
import { ICreateNote, useNotesHandlers } from '../../../../services/actions/notes/useNotesHandlers'
import { useAppStore } from '../../../../stores/application/useAppStore'
import { useNotesStore } from '../../../../stores/notes/useNotesStore'
import EmptyPage from '../../../../components/EmptyPage'
import ActionText from '../../../../components/ActionText'
import useMediaQuery from '../../../../components/useMediaQuery'
import SelectAllCheckbox from '../../../../components/SelectAllCheckbox'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'

interface INotesTableProps {
    notes?: any,
    notesFilters?: any
    isAllSelectedProps?: {
        isAllSelected: boolean
        setIsAllSelected: (e: any) => void
    }
    controlBarHeight?: number;
}
const NotesTable: FunctionComponent<INotesTableProps> = ({ notes, notesFilters, isAllSelectedProps, controlBarHeight}) => {
    const tableRef = useRef<HTMLDivElement>(null)

    const isMedium = useMediaQuery("(max-width: 1240px)");
    const isSmall = useMediaQuery("(max-width: 768px)");

    const { isAllSelected, setIsAllSelected  } = isAllSelectedProps || {}
    const { store: { notesParameters } } = useNotesStore()
    const { getConnectionByProfileIdHandler } = useConnectionsHandlers()
    const { openSidebar, closeSidebar, setDestructiveModal, hideModal  } = useAppHandlers()
    const { setInitialNotesFiltersHandler, deleteNoteHandler, createUpdateNoteHandler } = useNotesHandlers()
    const { store: { requestLoading }} = useAppStore()
    const [ notesLoading, setNotesLoading ] = useState<boolean>(true)
    const { pageSize, total } = notesParameters

    const { selectedNotes } = notesFilters
    const { store: { user, workspaceOwner }} = useAuthStore()

    const noResultTitle = "No Notes to Show";
    const noResultDescription = "Write and edit notes, keep them to yourself, or share them with your workspace members.";

    const [columns, setColumns] = useState([
        { header: 'Note', key: ['noteColumn'], show: true, width: '345px', showSmall: true },
        { header: 'Owner', key: ['ownerColumn'], show: true },
        { header: 'Connection', key: ['connectionColumn'], show: true, showMedium: true },
        { header: 'Access', key: ['accessColumn'], show: true },
        { header: 'Date created', key: ['dateCreatedColumn'], show: true },
        { header: 'Actions', key: ['actions'], show: true, showSmall: true }
    ])

    useEffect(() => {
        const offsetWidth = tableRef.current?.offsetWidth
        const adjustedColumns = calculateColumnWidth(offsetWidth, columns, isSmall, isMedium)
        setColumns(adjustedColumns)
    }, [isSmall, isMedium])

    useEffect(() => {
        if('getNotes' in requestLoading) setNotesLoading(requestLoading?.['getNotes'])
    }, [requestLoading]);

    const selectAllCheckboxHandler = () => {
        const selectedNotesIds = !isAllSelected ? notes.map((note: any) => note._id) : []
        setInitialNotesFiltersHandler({...notesFilters, selectedNotes: selectedNotesIds})
        setIsAllSelected && setIsAllSelected(!isAllSelected)
    }

    const selectCheckboxHandler = (e: React.MouseEvent<HTMLElement>, id: any) => {
        e.stopPropagation()
        const index = selectedNotes.indexOf(id)
        if (index !== -1) {
            const updatedIds = selectedNotes.filter((connectionId: any) => connectionId !== id);
            setInitialNotesFiltersHandler({...notesFilters, selectedNotes: updatedIds});
        } else {
            const updatedIds = [...selectedNotes, id];
            setInitialNotesFiltersHandler({...notesFilters, selectedNotes: updatedIds});
        }
    }

    const selectOnThisPageHadler = () => {
        const filteredNotes = workspaceOwner ? notes.map((note: any) => note._id) : notes.filter((note: any) => note?.user._id === user._id).map((note: any) => note._id)
        setInitialNotesFiltersHandler({...notesFilters, isAllSelected: false, selectedNotes: [...filteredNotes]})
    }

    const clearHandler = () => {
        setInitialNotesFiltersHandler({...notesFilters, isAllSelected: false, selectedNotes: []})
    }

    const onDeleteNoteHandler = async (noteId: string) => {
        setDestructiveModal({
            headingText: "Delete note",
            descriptionText: "Are you sure you want to delete this note?",
            onCancelHandler: () => hideModal(),
            onConfirmHandler: async () => {
                hideModal()
                deleteNoteConfirmed(noteId)
            }
        })
    }

    const deleteNoteConfirmed = async (noteId: string) => {
        await deleteNoteHandler(noteId)
    }

    return (
            <Table
            ref={tableRef}
            pageLoading={!notes?.length && notesLoading ? pageSize : undefined}
            noDataPage={<EmptyPage svgType='noteEmpty' title={noResultTitle} label={noResultDescription} />}
            $controlBarHeight={controlBarHeight}
            columns={[
                {
                    header:
                    <SelectAllCheckbox
                        isCheckboxChecked={selectedNotes?.length > 0 || (isAllSelected ?? false)}
                        pageSize={pageSize}
                        total={total}
                        paginated={workspaceOwner ? notes.length : notes.filter((note: any) => note?.user._id === user._id).length}
                        isAllSelected={isAllSelected ?? false}
                        selected={selectedNotes?.length}
                        label={'notes'}
                        selectAllHandler={selectAllCheckboxHandler}
                        selectOnThisPageHadler={selectOnThisPageHadler}
                        clearHandler={clearHandler}
                    />,
                    key: ['checkbox'],
                    width: '36px',
                    showSmall: true
                },

                ...columns
            ]}
            data={notes.map((note: any) => {
                return ({
                    object: { ...note },
                    ...(note?.user?._id === user._id || workspaceOwner) && {onRowClick: () => {openSidebar(SidebarTypes.NOTE_EDIT_CREATE, {
                        note: note,
                        editNote: true,
                        onCreateHandler: async (noteId: string | null, note: ICreateNote) => {const result = await createUpdateNoteHandler( noteId, note); if(result) closeSidebar(SidebarTypes.NOTE_EDIT_CREATE);},
                        onCloseHandler: async () => {closeSidebar(SidebarTypes.NOTE_EDIT_CREATE);}
                    })}},
                    checkbox: <StyledCheckbox
                        disabled={!workspaceOwner && note?.user?._id !== user._id}
                        checked={selectedNotes.some((selectedId: any) => selectedId === note._id)}
                        onClickHandler={(e: React.MouseEvent<HTMLElement>) => selectCheckboxHandler(e, note._id)}
                        checkboxId={note._id}/>,
                    noteColumn: <Text $ellipsis='5'>{<InnerHtml dangerouslySetInnerHTML={{ __html: sanitize(note.text) }} />}</Text>,
                    ownerColumn: <Text $bold $ellipsis='0'>{note?.user?.firstName + ' ' + note?.user?.lastName}</Text>,
                    connectionColumn: 
                        <Column>
                            <ActionText onClickHandler={(e: React.MouseEvent<HTMLElement>) => { e.stopPropagation(); getConnectionByProfileIdHandler(note?.connection?._id)} }><StyledText $associations>{note?.connection?.name}</StyledText></ActionText>
                        </Column>,
                    accessColumn: <Text $lighter>{note?.private ? 'Private' : 'Public'}</Text>,
                    dateCreatedColumn: <Text $lighter>{moment(note.createdAt).format('MMM D, YYYY HH:mm')}</Text>,
                    actions: 
                        <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e: any) => e.stopPropagation()}>
                            <EditDeleteActionDropdown
                                title="Duplicate note"
                                object={note}
                                type='note'
                                deleteEnabled={workspaceOwner || note?.user?._id === user._id}
                                onDeleteHandler={() => onDeleteNoteHandler(note._id)}
                                onEditHandler={() => {openSidebar(SidebarTypes.NOTE_EDIT_CREATE, { 
                                    note: note,
                                    editNote: true,
                                    duplicate: true,
                                    onCreateHandler: async (noteId: string | null, note: ICreateNote) => {const result = await createUpdateNoteHandler( noteId, note); if(result) closeSidebar(SidebarTypes.NOTE_EDIT_CREATE);},
                                    onCloseHandler: async () => {closeSidebar(SidebarTypes.NOTE_EDIT_CREATE);}
                                })}}
                            />
                        </div>,
                    ...note
                })
            })}
            />
    )
}
const InnerHtml = styled.div`
    & p { margin: 0; }
`
const StyledText = styled(Text) <any>`
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis; 

    ${({$associations}) => $associations && `
        color: ${styles.colors.primary600};
        font-weight: 700
    `}
`
const StyledCheckbox = styled(Checkbox)`
    height: fit-content;
`
export default NotesTable