import { FunctionComponent } from "react";
import { useAppStore } from "../../stores/application/useAppStore";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";

import StyledModal from "./styledModals/StyledModal";
import ConfirmationModal from "./modals/ConfirmationModal";
import DestructiveModal from "./modals/DestructiveModal";
import ModalSample from "./modals/ModalSample";
import MessageModal from "./modals/MessageModal";

import CancelFlow from "./modals/cancelation-flow/CancelFlow";
import PaymentConfirmedModal from "./modals/billing/PaymentConfirmedModal";
import PauseFlow from "./modals/cancelation-flow/PauseFlow";
import CustomColorModal from "../../fragments/modals/CustomColorModal";
import SyncConnectionsModal from "./modals/extension/SyncConnectionsModal";
import DisconnectConnectionsModal from "./modals/extension/DisconnectConnectionsModal";
import ExportConnectionsModal from "./modals/extension/ExportConnectionsModal";
import ConnectionsOverviewModal from "../../fragments/ConnectionsOverviewModal";
import CsvOverviewModal from "../../fragments/CsvOverviewModal";
import ScheduleDisconnectModal from "./modals/extension/ScheduleDisconnectModal";

export enum ModalActionType {
    CONFIRMATION_MODAL = "CONFIRMATION_MODAL",
    DESTRUCTIVE_MODAL = "DESTRUCTIVE_MODAL",
    MESSAGE_MODAL = "MESSAGE_MODAL",
    MODAL_SAMPLE = "MODAL_SAMPLE",
    MODAL_CANCEL_FLOW = "MODAL_CANCEL_FLOW",
    MODAL_PAUSE_FLOW = "MODAL_PAUSE_FLOW",
    PAYMENT_CONFIRM_MODAL = "PAYMENT_CONFIRM_MODAL",
    CUSTOM_COLOR_MODAL = "CUSTOM_COLOR_MODAL",
    SYNC_CONNECTIONS_MODAL = "SYNC_CONNECTIONS_MODAL",
    DISCONNECT_CONNECTIONS_MODAL = "DISCONNECT_CONNECTIONS_MODAL",
    EXPORT_CONNECTIONS_MODAL = "EXPORT_CONNECTIONS_MODAL",
    CONNECTIONS_OVERVIEW_MODAL = "CONNECTIONS_OVERVIEW_MODAL",
    CSV_OVERVIEW_MODAL = "CSV_OVERVIEW_MODAL",
    SCHEDULE_DISCONNECT_MODAL = "SCHEDULE_DISCONNECT_MODAL"
}

const AppModal: FunctionComponent = () => {
    const { store: { modal } } = useAppStore()
    const { hideModal } = useAppHandlers()

    if (!modal) return null

    let modalToShow;
    let modalWidth = "448px";

    switch (modal.type) {
        case ModalActionType.CONFIRMATION_MODAL:
            modalToShow = <ConfirmationModal />
            break
        case ModalActionType.DESTRUCTIVE_MODAL:
            modalToShow = <DestructiveModal />
            break
        case ModalActionType.MESSAGE_MODAL:
            modalToShow = <MessageModal />
            break
        case ModalActionType.MODAL_SAMPLE:
            modalToShow = <ModalSample />
            break
        case ModalActionType.MODAL_CANCEL_FLOW:
            modalToShow = <CancelFlow />
            break
        case ModalActionType.MODAL_PAUSE_FLOW:
            modalToShow = <PauseFlow />
            break
        case ModalActionType.PAYMENT_CONFIRM_MODAL:
            modalToShow = <PaymentConfirmedModal />
            break
        case ModalActionType.CUSTOM_COLOR_MODAL:
            modalToShow = <CustomColorModal {...modal.props}/>
            break
        case ModalActionType.SYNC_CONNECTIONS_MODAL:
            modalToShow = <SyncConnectionsModal {...modal.props} />
            break
        case ModalActionType.DISCONNECT_CONNECTIONS_MODAL:
            modalToShow = <DisconnectConnectionsModal {...modal.props} />
            break
        case ModalActionType.EXPORT_CONNECTIONS_MODAL:
            modalToShow = <ExportConnectionsModal {...modal.props} />
            break
        case ModalActionType.CONNECTIONS_OVERVIEW_MODAL:
            modalToShow = <ConnectionsOverviewModal {...modal.props} />
            break
        case ModalActionType.CSV_OVERVIEW_MODAL:
            modalToShow = <CsvOverviewModal {...modal.props} />
            break
        case ModalActionType.SCHEDULE_DISCONNECT_MODAL:
            modalToShow = <ScheduleDisconnectModal {...modal.props} />
            modalWidth = '520px';
            break
    }

    return (
        <StyledModal
            show={modal}
            onHide={hideModal}
            width={modalWidth}
        >
            {modalToShow}
        </StyledModal>
    )
}

export default AppModal