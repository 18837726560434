import { FunctionComponent, useEffect, useRef, useState } from 'react'
import Table from '../../../../../components/Table'
import Column from '../../../../../components/Column'
import ProfilePhoto from '../../../../../components/ProfilePhoto'
import Text from '../../../../../components/Text'
import Row from '../../../../../components/Row'
import moment from 'moment'
import Tag from '../../../../../components/Tag'
import { styles } from '../../../../../styles/themes/style'
import DropdownMenu from '../../../../../components/dropdown/DropdownMenu'
import DropdownItem from '../../../../../components/dropdown/DropdownItem'
import { useWorkspaceHandlers } from '../../../../../services/actions/workspace/useWorkspaceHandlers'
import EditDeleteActionDropdown from '../../../../../fragments/dropdowns/EditDeleteActionDropdown'
import styled from 'styled-components'
import SvgFragment from '../../../../../fragments/SvgFragment'
import AdminWorkspaceActionsDropdown from '../../../../../fragments/dropdowns/AdminWorkspaceActionsDropdown'
import { calculateColumnWidth } from '../../../../../utils'
import { useAppStore } from '../../../../../stores/application/useAppStore'

interface IWorkspaceUsersTableProps {
    columns?: any
    data?: any
    tableRef?: any
    setColumns: (e: any) => void
    setWorkspaceUsersFiltersHandler: (e?: any) => void
}

const WorkspaceUsersTable: FunctionComponent<IWorkspaceUsersTableProps> = ({ tableRef, columns, data, setColumns, setWorkspaceUsersFiltersHandler }) => {

    const { resendInviteHandler, revokeInviteHandler, updateWorkspaceUsersHandler } = useWorkspaceHandlers()

    const [workspaceUsers, setWorkspaceUsers] = useState<any>([])
    const [usersLoading, setUsersLoading] = useState<boolean>(true)
    const { store: { requestLoading }} = useAppStore()

    useEffect(() => {
        setWorkspaceUsers(data)
    }, [data])

    useEffect(() => {
        if('getWorkspaceUsers' in requestLoading || 'getInvites' in requestLoading) setUsersLoading(requestLoading?.['getWorkspaceUsers'] || requestLoading?.['getInvites'])
    }, [requestLoading]);

    useEffect(() => {
        const offsetWidth = tableRef.current?.offsetWidth

        const adjustedColumns = calculateColumnWidth(offsetWidth, columns)
        setColumns(adjustedColumns)

    }, [])
    
    return (
        <Table
            ref={tableRef}
            columns={[...columns]}
            pageLoading={usersLoading ? 5 : undefined}
            data={workspaceUsers.map((e: any) => {
                return({
                    nameColumn: (
                        <Row gap='5px' alignItems>
                            {e.type === 'workspaceUsers' ? (<>
                                <ProfilePhoto capitalLetters={`${e?.firstName?.charAt(0)?.toUpperCase()}${e?.lastName?.charAt(0)?.toUpperCase()}`} />
                                <Text $bold $ellipsis='0'>{e?.firstName + ' ' + e?.lastName}</Text> 
                            </>) : (<>
                                <Text $bold>No name</Text>
                            </>)}
                        </Row>
                    ),
                    emailColumn: <Text $ellipsis='1'>{e?.email}</Text>,
                    teamRoleColum: <Text>{e?.adminPermission ? 'Admin' : 'Member'}</Text>,
                    notesColumn: <Text>{e?.notePrivatePermission ? 'Public' : 'Private'}</Text>,
                    ...e.type === 'workspaceUsers' ? { lastActiveColumn: <Text>{moment(e?.lastSignInAt).format("MMM DD, YYYY")}</Text> } : null,
                    statusColumn: (
                        <Tag
                            $borderColor={(e.type === 'workspaceUsers' && e.active) ? styles.colors.green600 
                                : (e.type === 'workspaceInvites' && e.active && !e.resolved) ? '#ff9100'
                                    : (e.type === 'workspaceInvites' && !e.active && !e.active) ? '#545e77'
                                        : (e.type === 'workspaceUsers' && !e.active) ? '#545e77' : ''
                            }
                            $color={(e.type === 'workspaceUsers' && e.active) ? styles.colors.green200 
                                : (e.type === 'workspaceInvites' && e.active && !e.resolved) ? '#fff1e1'
                                    : (e.type === 'workspaceInvites' && !e.active && !e.active) ? '#dae0e8'
                                        : (e.type === 'workspaceUsers' && !e.active) ? '#dae0e8' : ''
                            }
                            title={(e.type === 'workspaceUsers' && e.active) ? 'Active' 
                                : (e.type === 'workspaceInvites' && e.active && !e.resolved) ? 'Pending' 
                                    : (e.type === 'workspaceInvites' && !e.active && !e.active) ? 'Revoked' 
                                        : (e.type === 'workspaceUsers' && !e.active) ? 'Deactvated' : ''
                            }
                        />
                    ),
                        actions: (<div style={{display: 'flex', justifyContent: 'flex-end'}}>
                           {!e.owner &&  <AdminWorkspaceActionsDropdown 
                                workspaceUser={e}
                                revokeInviteHandler={async () => {
                                    await revokeInviteHandler({ inviteId: e.id, body: { active: false } })
                                    setWorkspaceUsersFiltersHandler()
                                }}
                                resendInviteHandler={async () => {
                                    await resendInviteHandler(e.id)
                                    setWorkspaceUsersFiltersHandler()
                                }}
                                updateWorkspaceUsersHandler={async (e: any, flag: boolean) => {
                                    await updateWorkspaceUsersHandler(e, flag)
                                    setWorkspaceUsersFiltersHandler()
                                }}
                            />}
                        </div>)
                })
            })}
        />
    )
}

const StyledColumn = styled(Column)`

`

export default WorkspaceUsersTable