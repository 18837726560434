import { FunctionComponent, useEffect, useState } from "react";
import CustomModal from "../../CustomModal";
import { useAppHandlers } from "../../../../services/actions/app/useAppHandlers";
import Row from "../../../Row";
import Text from "../../../Text";
import styled from "styled-components";
import Column from "../../../Column";
import Checkbox from "../../../Checkbox";
import Input from "../../../Input";
import { styles } from "../../../../styles/themes/style";

interface IScheduleDisconnectModalProps {
  isAllSelected?: boolean;
  numberOfConnections?: number;
  onClickHandler: (enteredNumOfConnections: number) => void;
}

const ScheduleDisconnectModal: FunctionComponent<
  IScheduleDisconnectModalProps
> = ({
  onClickHandler,
  isAllSelected,
  numberOfConnections: numberOfSelectedConnections = 0,
}) => {
  const { hideModal } = useAppHandlers();
  const [step, setStep] = useState(1);
  const [numOfConnections, setNumOfConnections] = useState(0);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [hiddenScheduledRemovalWarning, setHiddenScheduledRemovalWarning] =
    useState(false);

  useEffect(() => {
    const storedValue = window.localStorage.getItem(
      "hiddenScheduledRemovalWarning"
    );
    if (storedValue === "true") {
      setHiddenScheduledRemovalWarning(true);
    } else {
      setHiddenScheduledRemovalWarning(false);
    }
  }, []);

  const doNotShowWarningAgain = async () => {
    if (checkboxChecked) {
      window.localStorage.setItem("hiddenScheduledRemovalWarning", "true");
    }
  };

  return step === 1 ? (
    <CustomModal
      headingText={"Schedule connections for removal"}
      closeButton
      icon="error"
      confirmButton={{
        text: "Schedule Removal",
        type: "red",
        bigButton: true,
        disable: numOfConnections !== numberOfSelectedConnections,
        onClickHandler: () =>
          hiddenScheduledRemovalWarning
            ? (setStep(3), onClickHandler(numOfConnections))
            : setStep(2),
      }}
      cancelButton={{
        text: "Cancel",
        mediumButton: true,
        onClickHandler: () => hideModal(),
      }}
    >
      <Column gap="10px">
        <Column gap="16px">
          <Row>
            <StyledText>
              {"You are about to schedule removal of "}
              <StyledBoldText $bold>
                {`${numberOfSelectedConnections} LinkedIn Connections.`}
              </StyledBoldText>
            </StyledText>
          </Row>
          <Column>
            <Text $lighter>{"• Your computer must be turned on"}</Text>
            <Text $lighter>{`• With stable internet connection and`}</Text>
            <Text
              $lighter
            >{`• You must be logged to your LinkedIn + LeadDelta.`}</Text>
          </Column>
          <Column>
            <StyledText $lighter>
              {`Removing connections from LinkedIn may take a couple of hours, or days. You’ll receive a notification in your LeadDelta once the removal is complete. `}
              <StyledBoldText $label>
                {"This action cannot be undone!"}
              </StyledBoldText>
            </StyledText>
          </Column>
        </Column>
        <Row gap="10px">
          <Text $bold $red>
            Note: Only your 1st-degree connections can be removed.
          </Text>
        </Row>
        <Row gap="10px">
          <StyledBoldText $bold>
            To confirm, type the number of selected connections to remove.
          </StyledBoldText>
        </Row>
        <Column>
          <Input
            type="number"
            $border={`2px solid ${styles.colors.black200}`}
            placeholder={numberOfSelectedConnections.toString()}
            onChangeHandler={(value: number) =>
              setNumOfConnections(Number(value))
            }
          />
        </Column>
      </Column>
    </CustomModal>
  ) : step === 2 ? (
    <CustomModal
      headingText={"Warning: This action is permanent"}
      icon="error"
      closeButton
      confirmButton={{
        text: "Confirm Removal",
        type: "red",
        bigButton: true,
        disable: numOfConnections < 1,
        onClickHandler: () => (
          onClickHandler(numOfConnections), doNotShowWarningAgain(), setStep(3)
        ),
      }}
      cancelButton={{
        text: "Cancel",
        mediumButton: true,
        onClickHandler: () => hideModal(),
      }}
    >
      <Column gap="10px">
        <Column>
          <Row>
            <StyledText>
              {"Confirm that you are about to remove "}
              <StyledBoldText $bold>
                {`${numOfConnections} LinkedIn Connections.`}
              </StyledBoldText>
            </StyledText>
          </Row>
          <Row>
            <StyledText>
              {"Important: "}
              <StyledBoldText $bold>
                {`This action cannot be undone!`}
              </StyledBoldText>
            </StyledText>
          </Row>
        </Column>
        <Row gap="10px" alignItems>
          <Checkbox
            checked={checkboxChecked}
            checkboxId={"doNotShowAgain"}
            onClickHandler={() => setCheckboxChecked(!checkboxChecked)}
          />
          <Text>{"Do not show this warning again"}</Text>
        </Row>
      </Column>
    </CustomModal>
  ) : (
    <CustomModal
      headingText={"You have successfully scheduled the connections removal"}
      closeButton
    >
      <Row>
        <StyledText>
          <StyledBoldText $bold>
            {`${numOfConnections} LinkedIn Connections`}
          </StyledBoldText>
          {" are scheduled to be removed."}
        </StyledText>
      </Row>
    </CustomModal>
  );
};

const StyledText = styled(Text)`
  display: inline-block;
  text-align: left;
  font-weight: normal;
`;
const StyledBoldText = styled(Text)`
  display: inline-block;
  font-weight: bold;
`;

export default ScheduleDisconnectModal;
