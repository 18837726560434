import { IConnection } from "../models/connection"

//Enums
export enum ActionType {
    SET_FILTER_HIDDEN_CONNECTIONS = "SET_FILTER_HIDDEN_CONNECTIONS",
    RESET_STORE = "RESET_STORE",
    SET_CONNECTIONS_FILTER = "SET_CONNECTIONS_FILTER",
    SET_CONNECTIONS_PARAMETERS = "SET_CONNECTIONS_PARAMETERS",
    SET_CONNECTIONS = "SET_CONNECTIONS",
    SET_FITLERING_TAGS = "SET_FITLERING_TAGS",
    SET_MULTIPLE_CONDITIONS_FILTERS = "SET_MULTIPLE_CONDITIONS_FILTERS",
    SET_CONNECTIONS_FILTER_OPTIONS = "SET_CONNECTIONS_FILTER_OPTIONS",
    RESET_CONNECTIONS_PAGINATION = "RESET_CONNECTIONS_PAGINATION",
    SET_SCHEDULED_REMOVAL_PARAMETERS = "SET_SCHEDULED_REMOVAL_PARAMETERS",
}

//Interfaces


//States
export type TConnectionsState = {
    connections: IConnection[],
    connectionsFilter: TConnectionsFilterState,
    connectionsParameters: TConnectionsParametersState,
    connectionFilterOptions: any,
    scheduledRemovalParameters: TScheduledRemovalParametersState,
}

export type TConnectionsFilterState = {
    // searchByName: string,
    // searchByCompany: string,
    // searchByHeadline: string,
    hiddenConnectionsValue: THiddenConnectionsState,
    tagCondition: string,
    selectedTags: string[],
    languageCondition: string,
    selectedLanguages: string[],
    usersMutualCondition: string,
}

export type TConnectionsParametersState = {
    page: number,
    pageSize: number,
    total: number
}

export type THiddenConnectionsState = {
    value: string,
    label: string,
}

export type TScheduledRemovalParametersState = {
    total?: number,
    count?: number,
    dailyCount?: number,
    dailyLimit?: number,
    inProgress?: boolean,
    lastRemovedAt?: string,
}

interface ISetConnectionsFilterAction {
    type: ActionType.SET_CONNECTIONS_FILTER
    payload: TConnectionsState['connectionsFilter']
}

interface ISetConnectionsParametersAction {
    type: ActionType.SET_CONNECTIONS_PARAMETERS
    payload: TConnectionsState['connectionsParameters']
}

interface ISetConnectionsAction {
    type: ActionType.SET_CONNECTIONS
    payload: TConnectionsState['connections']
}

interface IResetStoreAction {
    type: ActionType.RESET_STORE
}

interface ISetConnectionsFilterOptionsAction {
    type: ActionType.SET_CONNECTIONS_FILTER_OPTIONS
    payload: TConnectionsState['connectionFilterOptions']
}

interface IResetConnectionsPaginationAction {
    type: ActionType.RESET_CONNECTIONS_PAGINATION;
}

interface ISetScheduledRemovalParametersAction {
    type: ActionType.SET_SCHEDULED_REMOVAL_PARAMETERS
    payload: TConnectionsState['scheduledRemovalParameters']
}

export type TConnectionAction = ISetConnectionsFilterAction | ISetConnectionsParametersAction | IResetStoreAction | ISetConnectionsAction | ISetConnectionsFilterOptionsAction | IResetConnectionsPaginationAction | ISetScheduledRemovalParametersAction;