import React, { FormEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import CSVImportTable from './dataManagement/CSVImportTable'
import { ICSVQuery, useCSVImportRequest } from '../../../../services/apis/csv/useCSVimportRequest'
import { useCSVImportHandlers } from '../../../../services/actions/csv/useCSVImportHandlers'
import Row from '../../../../components/Row'
import TablePageNavigation from '../../../../components/TablePageNavigation'

interface ICSVImportProps { }

const CSVImport: FunctionComponent<ICSVImportProps> = () => {
    const tableRef = useRef<HTMLDivElement>(null)
    const { getCSVImportsHandler } = useCSVImportHandlers()
    const [ csvImports, setCsvImports ] = useState<any>([])
    const [ queryParams , setQueryParams ] = useState<ICSVQuery>({page: 1, pageSize: 50})
    const [ metaData, setMetaData ] = useState<any>({
        pages: 1,
        totalCount: 0
    })

    useEffect(() => {
        getCSVImports();
    }, [queryParams])


    useEffect(() => {
        const intervalId = setInterval(() => {
            getCSVImports();
        }, 5000);
        return () => clearInterval(intervalId);
    }, []);

    const getCSVImports = useCallback(async () => {
        const result = await getCSVImportsHandler(queryParams);
        setCsvImports(result.data)
        setMetaData(result.meta)
    }, [queryParams])

    const [columns, setColumns] = useState([
        { header: 'Name', key: ['nameDownload'], show: true, width: '300px', showSmall: true },
        { header: 'Status', key: ['status'], show: true, width: '10%', showSmall: true  },
        { header: 'New connections', key: ['newConnectionsCount'], show: true, width: '12%'  },
        { header: 'Updated connections', key: ['existingConnectionsCount'], show: true, width: '10%'  },
        { header: 'Error', key: ['invalidRowsCount'], show: true, width: '5%'  },
        { header: 'User', key: ['userEmail'], show: true, width: '350px'  },
        { header: 'Date', key: ['createdAtMoment'], show: true, width: '15%' }
    ])
    return <Container>
        <StyledRow>
            <TablePageNavigation 
                page={queryParams.page} 
                pageSize={queryParams.pageSize} 
                totalCount={metaData.totalCount}
                onPageChanged={(value: number) => setQueryParams({...queryParams, page: value})}
                onPageSizeChanged={(value: number) => setQueryParams({...queryParams, pageSize: value})}
            />
        </StyledRow>
        <CSVImportTable data={csvImports} columns={columns} tableRef={tableRef} setColumns={setColumns}/>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow-y:auto;
    height: 100%;
`
const StyledRow = styled(Row)`
    justify-content: flex-end;
    width: 100%;
`

export default CSVImport
