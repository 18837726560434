import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import DropdownMenu from '../../components/dropdown/DropdownMenu'
import SvgFragment from '../SvgFragment'
import DropdownItem from '../../components/dropdown/DropdownItem'
import { styles } from '../../styles/themes/style'
import Row from '../../components/Row'

interface IAdminWorkspaceActionsDropdownProps {
    workspaceUser?: any,
    className?: string,
    revokeInviteHandler: (e: any) => void
    resendInviteHandler: (e: any) => void
    updateWorkspaceUsersHandler: (e: any, flag: boolean) => void
}

const AdminWorkspaceActionsDropdown: FunctionComponent<IAdminWorkspaceActionsDropdownProps> = ({ workspaceUser, className, revokeInviteHandler, resendInviteHandler, updateWorkspaceUsersHandler }) => {
    const editDeleteActions = [
        {
            icon: 'close',
            title: `Revoke`,
            slug: 'revoke',
            enabled: true,
            visible: workspaceUser.active && 'resolved' in workspaceUser && !workspaceUser.resolved,
            destructive: false,
            onClickHandler: (e: any) => revokeInviteHandler && revokeInviteHandler(e)
        },
        {
            icon: 'plus',
            title: `Resend`,
            slug: 'resend',
            enabled: true,
            visible: 'active' in workspaceUser && 'resolved' in workspaceUser,
            onClickHandler: (e: any) => resendInviteHandler && resendInviteHandler(e)
        },
        {
            icon: 'close',
            title: `Remove from Workspace`,
            slug: 'remove',
            enabled: true,
            visible: workspaceUser.active && !('resolved' in workspaceUser),
            onClickHandler: (e: any) => updateWorkspaceUsersHandler && updateWorkspaceUsersHandler(workspaceUser, false)
        },
        {
            title: `Re-add`,
            slug: 'add',
            enabled: true,
            visible: !workspaceUser.active && !('resolved' in workspaceUser),
            onClickHandler: (e: any) => updateWorkspaceUsersHandler && updateWorkspaceUsersHandler(workspaceUser, true)
        }
    ]
    return <DropdownMenu tooltipMessage={'Actions'} className={className} SVGtype='threeDots' $hideToggle $transparent>
        {editDeleteActions.map((editDeleteAction, index) => editDeleteAction.visible ?
            <StyledDropdownItem key={index} $danger={editDeleteAction.destructive} onClickHandler={(e: any) => editDeleteAction.onClickHandler && editDeleteAction.onClickHandler(e)}>
                <Row alignItems gap="5px"><SvgFragment type={editDeleteAction.icon} />{editDeleteAction.title}</Row>
            </StyledDropdownItem> : null)}
    </DropdownMenu>
}

const StyledDropdownItem = styled(DropdownItem)`
    display: flex;
    align-items: center;
    gap: 5px;
    & .svg {
        height: 20px;
        width: 20px;
    }
    svg path {
        fill: ${({ theme: { primaryColor } }) => primaryColor};
    }
    svg line {
        stroke: ${({ theme: { primaryColor } }) => primaryColor};
    }
    ${({ $nonHoverable, $danger }) => !$nonHoverable && `&:hover, &:focus{
        svg path {
            fill: ${styles.colors.primary600};
        }
        svg line {
            stroke: ${styles.colors.primary600};
        }
        ${$danger && `svg path { fill: ${styles.colors.red400};`
        };`}
`

export default AdminWorkspaceActionsDropdown
