import React, { ReactNode, useEffect, useState } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import moment from 'moment';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { themes, styles } from './styles/themes/style';
import styled, { ThemeProvider } from 'styled-components';
import { analytic, getPageTitle, getPageUrl } from './utils';
import { AppRoutes, ISidebar } from './interfaces/store/appStore';
import { useAppStore } from './stores/application/useAppStore';
import { useAuthStore } from './stores/auth/useAuthStore';
import { useAppHandlers } from './services/actions/app/useAppHandlers';
import { useExternalHandlers } from './services/actions/navigation/useExternalHandlers';
import Reset from './styles/Reset';
import Header from './components/Header';
import AppModal from './components/modal/AppModal';
import AppRouter from './wrappers/AppRouter';
import Toast from './components/Toast';
import Spinner from './components/Spinner';
import AppContainer from './pages/AppContainer/AppContainer';
import Sidebar from './components/Sidebar';
import SidebarRender from './fragments/SidebarRender';
import CrispChat from './fragments/CrispChat';
import SvgFragment from './fragments/SvgFragment';
import Text from './components/Text';
import ActionText from './components/ActionText';
import Row from './components/Row';
import LoadingSpinner from './components/LoadingSpinner';
import Banner from './components/Banner'
import Column from './components/Column';

function App() {
  const { store: { user, trial, subscription, disablePremiumFeatures, workspaceOwner } } = useAuthStore()
  const { store: { spinner, extensionId, sidebar, darkMode, extensionInstalled, extensionVersion, onboardingStep, leadDeltaTabIsActive } } = useAppStore()
  const { checkExtensionStatus, closeSidebar, hideModal, setLeadDeltaTabIsActiveHandler } = useAppHandlers()
  const { openSyncMessage, updateLeadDeltaExtension } = useExternalHandlers()
  const [url, setUrl] = React.useState(window.location.href);
  const [crispChat, setCrispChat] = React.useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        setLeadDeltaTabIsActiveHandler(false);
      } else if (document.visibilityState === "visible" && !leadDeltaTabIsActive) {
        console.log("Tab is active again.");
        setLeadDeltaTabIsActiveHandler(true); // Update state to reflect that the tab is now active
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [leadDeltaTabIsActive]); // Depend on leadDeltaTabIsActive to re-run the effect when it changes

  const refs = {
    previousUrl: React.useRef<any>(''),
    url: React.useRef<any>(window.location.href)
  };

  useEffect(() => {
    window.$crisp = []; window.CRISP_WEBSITE_ID = "3666006c-6e66-4eec-90ce-7cb6f4489b3f"; (function () { let d = document; let s = d.createElement("script"); s.src = "https://client.crisp.chat/l.js"; s.async = true; d.getElementsByTagName("head")[0].appendChild(s); })();
  }, [])

  useEffect(() => {
    const observer = new MutationObserver(async (mutations) => {
      mutations.forEach(async (mutation) => {
        if (refs.url.current !== window.location.href) {
          refs.url.current = window.location.href;

          setUrl(window.location.href);
        }
      });
    });

    const config = {
      childList: true,
      subtree: true
    };

    observer.observe(window.document.body, config);

    return () => {
      observer.disconnect();
    };
  }, []);
  

  useEffect(() => {
    checkExtensionStatus();
  }, [extensionId])

  useEffect(() => {
    // analytic 
    analytic({
      event: 'virtualPageview',
      pageUrl: getPageUrl(),
      referrer: refs.previousUrl.current,
      pageState: '',
      pageTitle: getPageTitle()
    });

    // Previous url 
    refs.previousUrl.current = url;
  }, [url]);

  useEffect(() => {
    if(user?.preferences?.crispChat === false) {
      if(window.$crisp) window.$crisp.push(['do', 'chat:hide'])
      setCrispChat(false)
    } else {
      setCrispChat(true)
    }
  }, [user?.preferences])

  const lastSyncAtLocalStorage = window.localStorage.getItem('lastSyncAt')
  const lastSyncAt = lastSyncAtLocalStorage && lastSyncAtLocalStorage !== 'undefined' ? lastSyncAtLocalStorage : 0

  const LDBanners: {
    component: ReactNode,
    backgroundColor?: string,
    visible?: boolean
  } [] = [
      {
          component: <Row alignItems gap='10px'>
              <SvgFragment type='warning'/>
              <Text>{`Your trial ends ${moment().to(moment(subscription?.periodEnd * 1000))}.`} {`Save 30% by subscribing to a `}<Link to={AppRoutes.BILLING} style={{ textDecoration: 'none' }}> <Text>{'Yearly plan now'}</Text> </Link>{` (grandfathered in forever).`}</Text>
          </Row>,
          visible: trial && onboardingStep < 0
      },
      {
          component: <Row alignItems gap='10px'>
              <LoadingSpinner />
              <Text $white>{`Syncing your connections... In the meantime, why not start creating tags?`} <ActionText invertedVariable onClickHandler={() =>openSyncMessage()}>Learn more.</ActionText></Text>
          </Row>,
          backgroundColor: styles.colors.primary600,
          visible: (!user?.isSynced && moment().isBefore(moment(lastSyncAt).add(20, 'minutes'))) 
      }
      ,
      {
          component: <Row alignItems gap='10px'>
              <Text $white>{`Please update your LeadDelta Chrome Extension to the latest version. Find step-by-step`} <ActionText invertedVariable onClickHandler={() =>updateLeadDeltaExtension()}>instructions here.</ActionText></Text>
          </Row>,
          backgroundColor: styles.colors.primary600,
          visible: extensionInstalled && extensionVersion !== process.env.REACT_APP_VERSION 
      },
      {
        component: <Row alignItems gap='10px'>
          <Link to={AppRoutes.BILLING} style={{ textDecoration: 'none', fontWeight: 'normal' }}> <Text $white><b>BlackFriday:</b> Get extra <b>20% OFF!</b> 🎉 Select yearly plan and enjoy total of <b>50%</b> savings on your first year. Use <b>BF24</b> at the checkout (new users only). </Text></Link>
        </Row>,
        backgroundColor: 'black',
        visible: false /* (trial && onboardingStep < 0) || (disablePremiumFeatures && workspaceOwner) */ /* Banner for trials and expired trials (workspace owner)  */
      }
  ];

  return (
    <ThemeProvider theme={themes[darkMode ? 'dark' : 'light']}>
      <Reset />

      <BrowserRouter>
        <AppContainer>
         {!!sidebar.length  && sidebar.map((sidebarItem: ISidebar) => 
              <Sidebar zIndex={sidebarItem.zIndex} onClose={() => {
                if(typeof sidebarItem.type === 'string' && ['connectionActions'].includes(sidebarItem.type)) hideModal()
                closeSidebar(sidebarItem.type)
              }}>
                <SidebarRender key={sidebarItem.zIndex} sidebarType={sidebarItem.type} {...sidebarItem.props}/>
              </Sidebar>)}
          {user && <StyledBannerBox>{LDBanners?.map((LDBanner: any) => LDBanner.visible && <Banner backgroundColor={ LDBanner.backgroundColor }>{LDBanner.component}</Banner>)}</StyledBannerBox>}
          <Header />
          <PagesContainer LDBanners={LDBanners} >
            <AppRouter />
          </PagesContainer>
          <AppModal />
          <Toast />
          <Spinner showHideSpinner={spinner} />
          {crispChat && <CrispChat />}
        </AppContainer>
      </BrowserRouter>
    </ThemeProvider>
  );
}

const PagesContainer = styled.div<{LDBanners: any}>`
    width: 100%;
    height: calc(100% - ${styles.lengths.navbarHeight} - ${({ LDBanners }) => LDBanners.filter((item: any) => item.visible).length * 40}px);
    overflow-y: auto;
`
const StyledBannerBox = styled(Column)`
    width: 100%;
`
export default App;
