import React, { FormEvent, FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Row from '../../../../components/Row'
import TablePageNavigation from '../../../../components/TablePageNavigation'
import WorkspaceUsersTable from './dataManagement/WorkspaceUsersTable'
import { useWorkspaceHandlers } from '../../../../services/actions/workspace/useWorkspaceHandlers'
import { calculateColumnWidth } from '../../../../utils'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import Text from '../../../../components/Text'
import Column from '../../../../components/Column'
import Button from '../../../../components/Button'
import { AppRoutes, SidebarTypes } from '../../../../interfaces/store/appStore'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { useNavigate } from 'react-router-dom'
import { IBillingPlanV2, IPrice } from '../../../../interfaces/models/billingPlan'
import { useBillingHandlers } from '../../../../services/actions/billing/useBillingHandlers'
import DropdownMenu from '../../../../components/dropdown/DropdownMenu'
import DropdownItem from '../../../../components/dropdown/DropdownItem'
import { styles } from '../../../../styles/themes/style'
import { useWorkspaceUsersStore } from '../../../../stores/workspaceUsers/useWorkspaceUsersStore'
import { useWorkspaceUsersActions } from '../../../../stores/workspaceUsers/useWorkspaceUsersActions'
import useMediaQuery from '../../../../components/useMediaQuery'

interface IWorkspaceAdminProps { }


const WorkspaceAdmin: FunctionComponent<IWorkspaceAdminProps> = () => {
    const tableRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const isSmall = useMediaQuery("(max-width: 768px)")

    const { getBillingPlansSettingsHandler } = useBillingHandlers()
    const { getWorkspaceUsersHandler, getInvitesHandlers, getWorkspaceUsersAndInvitesHandler } = useWorkspaceHandlers()
    const { openSidebar } = useAppHandlers()

    const { store: { workspace, subscription, lifetime, workspaceOwner, workspaceAdmin, trial } } = useAuthStore()
    const { store: { workspaceUsers, workspaceUsersFilters, workspaceUsersParameters  } } = useWorkspaceUsersStore()

    const { setWorkspaceUsers, setWorkspaceUsersFilters, setWorkspaceUsersParameters} = useWorkspaceUsersActions()

    const [ selectedPrice, setSelectedPrice ] = useState<IPrice | undefined>(undefined)
    const [ selectedBillingPlan, setSelectedBillingPlan] = useState<IBillingPlanV2 | undefined>(undefined)
    const [ search, setSearch ] = useState<string>('all')

    useEffect(() => {
        if(workspaceOwner === false && workspaceAdmin === false) navigate(AppRoutes.SETTINGS_DATA_MANAGEMENT)
    }, [workspaceOwner, workspaceAdmin])

    const [columns, setColumns] = useState([
        { header: 'Name', key: ['nameColumn'], show: true, width: '160px', showSmall: true },
        { header: 'Email', key: ['emailColumn'], show: true, showSmall: true },
        { header: 'Team Role', key: ['teamRoleColum'], show: true, showSmall: true },
        { header: 'Last Active', key: ['lastActiveColumn'], show: true },
        { header: 'Notes', key: ['notesColumn'], show: true },
        { header: 'Status', key: ['statusColumn'], show: true, showSmall: true },
        { header: 'Actions', key: ['actions'], show: true, width: '48px', showSmall: true }
    ])

    const getAllWorkspaceUsers = useCallback(async () => {
        const {activeUsers, removedUsers, invites} = await getWorkspaceUsersAndInvitesHandler(workspaceUsersFilters);

        const allUsers = [
            ...activeUsers?.response.map((user: any) => (
                {
                    ...user,
                    type: 'workspaceUsers'
                }
            )).sort((a: any, b: any) => (b.owner ? 1 : 0) - (a.owner ? 1 : 0)),
            ...invites?.response.map((invite: any) => (
                {
                    id: invite._id,
                    email: invite.to.email,
                    resolved: invite.resolved,
                    active: invite.active,
                    type: 'workspaceInvites'
        
                }
            )),
            ...removedUsers?.response.map((user: any) => (
                {
                    ...user,
                    type: 'workspaceUsers'
                }
            ))
        ]

        setWorkspaceUsers(allUsers)
        setWorkspaceUsersParameters({...workspaceUsersParameters, total: activeUsers?.metadata?.total + invites?.metadata?.total + removedUsers?.metadata?.total})
    }, [workspaceUsersFilters])
    
    const getWorkspaceUsers = useCallback(async () => {
        const result = await getWorkspaceUsersHandler(workspaceUsersFilters);
        setWorkspaceUsers(result?.response.map((user: any) => (
            {
                ...user,
                type: 'workspaceUsers'
            }
        )).sort((a: any, b: any) => (b.owner ? 1 : 0) - (a.owner ? 1 : 0)))
        setWorkspaceUsersParameters(result.metadata)
    }, [workspaceUsersFilters])

    const getWorkspaceInvites = useCallback(async () => {
        const result = await getInvitesHandlers(workspaceUsersFilters);
        setWorkspaceUsers(result?.response.map((invite: any) => (
            {
                id: invite._id,
                email: invite.to.email,
                resolved: invite.resolved,
                active: invite.active,
                type: 'workspaceInvites'
    
            }
        )))
        setWorkspaceUsersParameters(result.metadata)
    }, [workspaceUsersFilters])

    const handlePriceAndPlan = useCallback(async () => {
        const billingPlans = await getBillingPlansSettingsHandler()
        let selectedPrice
        const selectedBillingPlan = billingPlans.find((plan: IBillingPlanV2) => {
            selectedPrice = plan.prices.find((price: IPrice) => price.priceId === subscription.priceId && price.productId === subscription.productId)
            if(selectedPrice) return true
        })
        if(selectedBillingPlan) setSelectedBillingPlan(selectedBillingPlan)
        if(selectedPrice) setSelectedPrice(selectedPrice)
    },
    [])

    const handleAdditionalSeats = useCallback(async () => {
        if (selectedBillingPlan && selectedPrice) navigate(AppRoutes.BILLING_PLAN, { state: { changeSeats: true, preSelectedBillingPlan: selectedBillingPlan, preSelectedPrice: selectedPrice }})
        else navigate(AppRoutes.BILLING)
    },
    [subscription, selectedPrice, selectedBillingPlan])

    const setWorkspaceUsersFiltersHandler = async (props: any) => {
        setWorkspaceUsers([])

        if(props) {
            setSearch(props)
            setWorkspaceUsersFilters({...workspaceUsersFilters, active: (props === 'active' || props === 'all') ? 'true' : 'false'})
        } else {
            setWorkspaceUsersFilters(workspaceUsersFilters)
        }
    }

    useEffect(() => {
        handlePriceAndPlan()
    }, [])

    useEffect(() => {
        if(search === 'all') getAllWorkspaceUsers();
        if(search === 'active' || search === 'removed') getWorkspaceUsers();
        if(search === 'invites') getWorkspaceInvites();
    }, [workspaceUsersFilters])

    useEffect(() => {
        const offsetWidth = tableRef.current?.offsetWidth
        const adjustedColumns = calculateColumnWidth(offsetWidth, columns, isSmall)
        setColumns(adjustedColumns)
    }, [workspaceUsers, isSmall])

    return <Container>
        <StyledRow spaceBetween>
            <StyledColumn>
                <Text $heading4>{workspace.name}</Text>
                <Text>{`${workspace.seatsUsed} of ${trial ? 'unlimited' : subscription?.quantity + (lifetime ? 1 : 0)} seats used`}</Text>
            </StyledColumn>
            <StyledRow gap='10px'>
                <Button $type='light-blue' $extraBigButton onClickHandler={() => openSidebar(SidebarTypes.WORKSPACE_CREATE, {workspace})}>Change Workspace Name</Button>
                <StyledDropdownMenu title={search === 'invites' ? 'Pending members' : search === 'all' ? 'All members' : search === 'active' ? 'Active members' : search === 'removed' && 'Removed members'}>
                    <DropdownItem onClickHandler={() => setWorkspaceUsersFiltersHandler('all')}>All</DropdownItem>
                    <DropdownItem onClickHandler={() => setWorkspaceUsersFiltersHandler('active')}>Active</DropdownItem>
                    <DropdownItem onClickHandler={() => setWorkspaceUsersFiltersHandler('invites')}>Pending</DropdownItem>
                    <DropdownItem onClickHandler={() => setWorkspaceUsersFiltersHandler('removed')}>Removed</DropdownItem>
                </StyledDropdownMenu>
                <Button $type='blue' $bigButton onClickHandler={handleAdditionalSeats}>Buy seats</Button>
            </StyledRow>
        </StyledRow>
        <StyledTablePageNavigation 
                page={workspaceUsersFilters.page} 
                pageSize={workspaceUsersFilters.pageSize} 
                totalCount={workspaceUsersParameters.total}
                onPageChanged={(value: number) => setWorkspaceUsersFilters({...workspaceUsersFilters, page: value})}
                onPageSizeChanged={(value: number) => setWorkspaceUsersFilters({...workspaceUsersFilters, pageSize: value})}
        />
        <WorkspaceUsersTable tableRef={tableRef} data={workspaceUsers} columns={columns} setColumns={setColumns} setWorkspaceUsersFiltersHandler={setWorkspaceUsersFiltersHandler} />
    </Container>
}
const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    overflow-y:auto;
    height: 100%;
`
const StyledRow = styled(Row)`
    width: 100%;

    @media (max-width: 786px) {
        flex-direction: column;
        gap: 10px;
    }
`
const StyledColumn = styled(Column)`
    width: 100%;

    @media (max-width: 786px) {
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }
`
const StyledDropdownMenu = styled(DropdownMenu)`
    height: 34px;
    width: 150px;
    background-color: ${styles.colors.primary100};

    & .dropdown-toggle {
        padding: 0 7px;
    }

    @media (max-width: 786px) {
        flex-direction: column;
    }
`
const StyledTablePageNavigation = styled(TablePageNavigation)`
    margin-left: auto;
`
export default WorkspaceAdmin
