import { useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"

export interface ITasksQueryParams {
    total?: boolean,
    completed?: boolean,
    page?: number,
    pageSize?: number,
    text?: string,
    allWorkspaces?: boolean,
    isForbidden?: boolean,
    sort?: string,
    endFrom?: number | null,
    endTo?: number | null,
    status?: string,
    repeatingUnit?: string,
    workspaces?: string[],
    connections?: string,
    byLoggedUser?: boolean
}

export const useTaskRequest = () => {
    const { sendRequest } = useAxios()
    const { store: { authData: { accessToken } } } = useAuthStore()

    const route = '/tasks'

    //GET
    const getTasks = (params: ITasksQueryParams) => {
        return sendRequest({
            slug: 'getTasks',
            url: `${route}/query`,
            method: 'GET',
            params
        })
    };
    const getAllTasks = (params: any) => {
        return sendRequest({
            slug: 'getAllTasks',
            url: `${route}/query?${params}`,
            method: 'GET'
        })
    };

    //PUT
    const updateTask = (id: string, payload: any) => {
        return sendRequest({
            slug: 'updateTask',
            url: `${route}/${id}`,
            method: 'PUT',
            payload
        })
    };

    const bulkUpdateTasks = (payload: any) => {
        return sendRequest({
            slug: 'bulkUpdateTasks',
            url: `${route}/bulk`,
            method: 'PUT',
            payload
        })
    };

    //POST
    const createTask = (payload: any) => {
        return sendRequest({
            displayToast: false,
            slug: 'createTask',
            url: `${route}`,
            method: 'POST',
            payload,
            all: true
        })
    };

    const bulkRemoveTasks = (payload: any) => {
        return sendRequest({
            slug: 'bulkRemoveTasks',
            url: `${route}/bulk/remove`,
            method: 'PUT',
            payload,
            all: true
        })
    };

    const removeTask = (taskId: string) => {
        return sendRequest({
            slug: 'bulkRemoveTasks',
            url: `${route}/${taskId}`,
            method: 'DELETE',
            all: true
        })
    };

    return {
        getTasks,
        getAllTasks,
        updateTask,
        createTask,
        bulkRemoveTasks,
        bulkUpdateTasks,
        removeTask
    };
}